<template>
  <div class="px-4 pt-10">
    <van-cell-group class="mb-10">
      <van-cell
        title="机构编号"
        is-link
        :value="form.regid"
        @click="show = true"
        :error-message="error.reg_id"
      />
      <van-field
        label="姓名"
        placeholder="请输入姓名"
        v-model="form.name"
        :error-message="error.name"
      />
      <van-field
        label="手机号"
        placeholder="请输入手机号"
        v-model="form.mob"
        type="tel"
        maxlength="11"
        :error-message="error.mobile"
      />
      <van-field
        label="密码"
        placeholder="请输入密码"
        v-model="form.password"
        type="password"
        :error-message="error.password"
      />
      <van-field
        label="确认密码"
        placeholder="请再次输入密码"
        v-model="repeatPassword"
        type="password"
      />
      <van-cell>
        <div class="flex">
          <div class="van-field__label">
            类型
          </div>
          <van-radio-group v-model="form.flagtype">
            <van-radio name="1">员工</van-radio>
            <van-radio class="my-2" name="2">家属</van-radio>
            <van-radio name="3">护工</van-radio>
          </van-radio-group>
        </div>
      </van-cell>
      <van-field
        v-if="form.flag_type !== '1'"
        label="床位编号"
        placeholder="请输入床位编号"
        v-model="form.oldbedid"
        :error-message="error.bed_id"
      />
    </van-cell-group>
    <van-button @click="submit" type="primary" block>注册</van-button>
    <van-popup v-model="show" position="bottom" closeable close-icon="">
      <van-picker
        title="机构"
        show-toolbar
        :columns="agents"
        @confirm="onConfirm"
        @change="() => {}"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { register } from '@/api'
import { Dialog } from 'vant'
export default {
  data() {
    return {
      show: false,
      agents: ['A001', 'A002', 'B001', 'B002'],
      form: {
        regid: '',
        name: '',
        mob: '',
        password: '',
        flagtype: '1',
        oldbedid: ''
      },
      repeatPassword: '',
      submitting: false
    }
  },

  computed: {
    error() {
      if (!this.submitting) {
        return {}
      }
      const form = this.form
      return {
        reg_id: !form.regid ? '机构编号为必填项' : '',
        name: !form.name ? '姓名为必填项' : '',
        mobile: form.mobile.length < 11 ? '手机号格式不正确' : '',
        password: !form.password
          ? '密码为必填项'
          : form.password !== this.repeatPassword
          ? '两次密码输入不一致'
          : '',
        bed_id:
          form.flagtype !== '1' && !form.oldbedid ? '床位编号为必填项' : ''
      }
    }
  },

  methods: {
    onConfirm(val) {
      this.show = false
      this.form = {
        ...this.form,
        regid: val
      }
    },
    async submit() {
      this.submitting = true
      const form = this.form
      if (
        !form.regid ||
        !form.name ||
        !form.password ||
        form.password !== this.repeatPassword ||
        (form.flagtype !== '1' && !form.oldbedid)
      ) {
        return
      }

      try {
        await register(form)
        await Dialog.alert({
          message: '注册成功！'
        })
        this.$router.go(-1)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss">
</style>